












import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      email: null as string | null
    };
  },
  created() {
    this.email = this.$route.query.email as string;
  }
});
